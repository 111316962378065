import router from './router'
import createAuth from './auth'
import toast from './toast'
import createPace from './pace'
import inputmask from './inputmask'
import { http } from './http'
import { injectToStore, injectToApp } from './injectPlugin'

export const auth = createAuth(router, http)
export const pace = createPace(http)

export { router, http, toast, inputmask, injectToStore, injectToApp }