import axios from 'axios'
import { inject } from 'vue'

const injectKey = 'http'

export const useHttp = () => {
  return inject(injectKey)
}

export const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

Object.defineProperties(http, {
  install: {
    get() {
      return (app) => {
        app.provide(injectKey, http)
      }
    }
  },
  isCancel: {
    get() {
      return axios.isCancel
    }
  },
  cancelToken: {
    get() {
      return axios.CancelToken
    }
  },
})
