import { createStore, createLogger } from 'vuex'
import cart from './modules/cart'
import products from './modules/products'
import orders from './modules/orders'
import contacts from './modules/contacts'
import addresses from './modules/addresses'

const isDev = process.env.NODE_ENV !== 'production'

export default createStore({
	modules: {
    cart,
    products,
    orders,
    contacts,
    addresses
  },
	strict: isDev,
	plugins: isDev ? [createLogger()] : []
})