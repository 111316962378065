import BaseApi from './BaseApi'

export default class Policy extends BaseApi {
  fetchPrivacyPolicy() {
    return this.http.get('/policy/privacy')
  }

  fetchCookiePolicy() {
    return this.http.get('/policy/cookie')
  }
}
