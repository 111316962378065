<template>
  <div class="login" style="background-image: url(/img/login/login-bg.jpg);">
    <div class="container">
      <div class="login__inner">
        <a class="login__logo" href="#">
          <img src="/img/login/logo-mirrored.svg" alt="Логотип">
        </a>
        <div class="login__content">
          <div class="login__left">
            <h1 class="login__title">
              Автоматизация
              поставок
            </h1>
            <p v-if="contacts" class="login__text">
              Служба поддержки клиентов
            </p>
            <ul class="login__list">
              <li v-if="contacts?.phone" class="contact-line login__item login__item--phone">
                <a class="contact-line__link contact-line__link--mobile" :href="`tel:${contacts.phone}`">
                  <span class="contact-line__icon">
                    <svg class="s-icon s-icon--xs">
                      <use xlink:href="/img/svg/sprite1.svg#phone" />
                    </svg>
                  </span>
                  {{ contacts.phone }}
                </a>
                <span class="contact-line__link">
                  <span class="contact-line__icon">
                    <svg class="s-icon s-icon--xs">
                      <use xlink:href="/img/svg/sprite1.svg#phone" />
                    </svg>
                  </span>
                  {{ contacts.phone }}
                </span>
              </li>
              <li v-if="contacts?.whatsapp" class="contact-line login__item">
                <a
                  class="contact-line__link"
                  :href="`https://wa.me/${contacts.whatsapp}`"
                  target="_blank"
                  rel="noopener"
                >
                  <span class="contact-line__icon">
                    <svg class="s-icon">
                      <use xlink:href="/img/svg/sprite1.svg#whatsapp" />
                    </svg>
                  </span>
                  {{ contacts.whatsapp }}
                </a>
              </li>
              <li v-if="contacts?.additional_phone" class="contact-line login__item login__item--phone">
                <a class="contact-line__link contact-line__link--mobile" :href="`tel:${contacts.additional_phone}`">
                  <span class="contact-line__icon">
                    <svg class="s-icon s-icon--xs">
                      <use xlink:href="/img/svg/sprite1.svg#phone" />
                    </svg>
                  </span>
                  {{ contacts.additional_phone }}
                </a>
                <span class="contact-line__link">
                  <span class="contact-line__icon">
                    <svg class="s-icon s-icon--xs">
                      <use xlink:href="/img/svg/sprite1.svg#phone" />
                    </svg>
                  </span>
                  {{ contacts.additional_phone }}
                </span>
              </li>
              <li v-if="contacts?.telegram" class="contact-line login__item">
                <a
                  class="contact-line__link"
                  :href="`http://t.me/${contacts.telegram}`"
                  target="_blank"
                  rel="noopener"
                >
                  <span class="contact-line__icon">
                    <svg class="s-icon">
                      <use xlink:href="/img/svg/sprite1.svg#telegram" />
                    </svg>
                  </span>
                  {{ contacts.telegram }}
                </a>
              </li>
              <li v-if="contacts?.email" class="contact-line login__item">
                <a
                  class="contact-line__link contact-line__link--mobile"
                  :href="`mailto:${contacts.email}`"
                  target="_blank"
                  rel="noopener"
                >
                  <span class="contact-line__icon">
                    <svg class="s-icon">
                      <use xlink:href="/img/svg/sprite1.svg#email" />
                    </svg>
                  </span>
                  {{ contacts.email }}
                </a>
                <span class="contact-line__link" rel="noopener">
                  <span class="contact-line__icon">
                    <svg class="s-icon">
                      <use xlink:href="/img/svg/sprite1.svg#email" />
                    </svg>
                  </span>
                  {{ contacts.email }}
                </span>
              </li>
            </ul>
            <div class="descr-block login__descr">
              <img
                class="descr-block__img"
                src="/img/footer/3pl.svg"
                alt=""
              >
              <p class="descr-block__text">
                Мы способствуем развитию бизнеса наших клиентов, внедряя наиболее передовые и
                эффективные решения в области логистики.
              </p>
            </div>
          </div>
          <div class="login__right">
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { FETCH_CONTACTS } from '@/store/modules/contacts/actionTypes'

export default {
  setup () {
    const store = useStore()
    onMounted(() => store.dispatch(FETCH_CONTACTS))
    const contacts = computed(() => store.getters.getContacts)
    return {
      contacts
    }
  }
}
</script>