export default class BaseApi {
  constructor(http) {
    this.http = http
    this.cancelTokens = {}
  }

  handleCancellation(methodName, cancelable = true) {
    let cancelToken = this.cancelTokens[methodName]
    cancelToken && cancelToken.cancel(`${methodName} canceled`)
    cancelToken = this.http.cancelToken.source()
    if (cancelable) {
      this.cancelTokens[methodName] = cancelToken
    }
    return cancelToken
  }
}
