import { createApp } from 'vue'
import { createApi, injectKey } from './api'
import store from './store'
import App from './App.vue'
import { router, http, toast, inputmask, auth, pace, injectToStore } from './plugins'

const app = createApp(App)

const api = createApi(http);

[store, router, http, auth, api, toast, pace, inputmask].forEach(plugin => app.use(plugin))

injectToStore(store, injectKey, api)
  
app.mount('#app')
