<template>
  <the-header />
  <the-main />
  <the-footer />
</template>

<script>
import TheFooter from '@/components/TheFooter'
import TheHeader from '@/components/TheHeader'
import TheMain from '@/components/TheMain'

export default {
  components: {
    TheFooter,
    TheHeader,
    TheMain
  }
}
</script>
