import { useStore } from 'vuex'
import { computed } from 'vue'

export default () => {
  const store = useStore()
  const cart = computed(() => store.getters.getCart)
  const cartTotalPrice = computed(() => store.getters.getCartTotalPrice)
  const cartVatAmount = computed(() => store.getters.getCartVatAmount)
  const isCartLoading = computed(() => store.getters.isCartLoading)
  const isCartSubmitting = computed(() => store.getters.isCartSubmitting)
  const cartItemsCount = computed(() => store.getters.getCartItemsCount)
  return {
    isCartSubmitting,
    cart,
    cartTotalPrice,
    cartVatAmount,
    isCartLoading,
    cartItemsCount
  }
}