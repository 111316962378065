<template>
  <LayoutMain v-if="isAuth">
    <router-view />
  </LayoutMain>

  <LayoutUnAuth v-else>
    <router-view />
  </LayoutUnAuth>
</template>

<script>
import LayoutUnAuth from "./LayoutUnAuth"
import LayoutMain from "./LayoutMain"
import { useAuth } from '@websanova/vue-auth/src/v3.js'
import { computed } from "vue";

export default {
  name: "LayoutEmpty",
  components: {
    LayoutUnAuth,
    LayoutMain
  },
  setup() {
    const auth = useAuth()

    const isAuth = computed(() => {
      return auth.check()
    })

    return {
      isAuth
    }
  }
}
</script>

<style scoped>

</style>