import VueProgressBar from '@aacassandra/vue3-progressbar'
import { inject, ref } from 'vue'

export const injectKey = 'pace'

export const usePace = () => {
  return inject(injectKey)
}

export class Pace {
  constructor() {
    this.counter = ref(0)
  }

  start() {
    if (this.counter.value === 0) {
      this.progress.start()
    }
    this.counter.value++
  }

  finish() {
    if (this.counter.value > 0) {
      this.counter.value--
    }
    if (this.counter.value === 0) {
      this.progress.finish()
    }
  }

  install(app) {
    app.use(VueProgressBar, {
      color: '#fff',
      failedColor: '#f3532d',
      thickness: '2px',
      transition: {
        speed: '2s',
        opacity: '0.2s',
        termination: 700,
      },
      autoRevert: true,
      location: 'top',
      inverse: false
    })
    const { $Progress } = app.config.globalProperties
    Object.defineProperty(this, 'progress', {
      get: () => $Progress
    })
    app.provide(injectKey, this)
  }
}