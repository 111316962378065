import Toast, { TYPE } from 'vue-toastification'

export default {
  install(app) {
    app.use(Toast, {
      transition: 'Vue-Toastification__bounce',
      maxToasts: 5,
      newestOnTop: true,
      toastDefaults: {
        [TYPE.ERROR]: {
          position: 'top-right',
          timeout: 2500,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          hideProgressBar: true,
          closeButton: false
        },
        [TYPE.SUCCESS]: {
          position: 'top-right',
          timeout: 2500,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.6,
          hideProgressBar: true,
          closeButton: false
        }
      }
    })
  }
}