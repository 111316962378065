import BaseApi from './BaseApi'

export default class Products extends BaseApi {
  fetchFilters() {
    return this.http.get('/products/filters')
  }

  fetchProducts(params, cancelable = true) {
    const cancelToken = this.handleCancellation(this.fetchProducts.name, cancelable).token
    return this.http.get('/products', { params, cancelToken })
  }

  searchProducts(params) {
    const cancelToken = this.handleCancellation(this.searchProducts.name).token
    return this.http.get('/products/search', { params, cancelToken, progress: false })
  }
}
