<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__inner">
        <div class="footer__content">
          <router-link class="footer__logo" to="/">
            <picture class="footer__image">
              <source srcset="/img/logo.svg" media="(max-width: 992px)">
              <img src="/img/login/logo-mirrored.svg" alt="Логотип">
            </picture>
          </router-link>
          <div class="footer__subtitle">
            ТОО «DSF Trading Co». Сервис заказа товаров.
          </div>
          <div class="descr-block">
            <img class="descr-block__img" src="/img/footer/3pl.svg" alt="">
            <p class="descr-block__text">
              3PL оператор с командой профессионалов и богатым опытом в области торговой логистики.
            </p>
          </div>
        </div>
        <div v-if="contacts" class="footer__block footer__block--first">
          <div class="footer__title">
            Контакты
          </div>
          <ul class="footer__list">
            <li v-if="contacts?.phone" class="contact-line footer__item">
              <a class="contact-line__link contact-line__link--mobile" :href="`tel:${stripPhoneNumber(contacts.phone)}`">
                <span class="contact-line__icon">
                  <svg class="s-icon s-icon--xs">
                    <use xlink:href="/img/svg/sprite1.svg#phone" />
                  </svg>
                </span>
                {{ contacts.phone }}
              </a>
              <span class="contact-line__link">
                <span class="contact-line__icon">
                  <svg class="s-icon s-icon--xs">
                    <use xlink:href="/img/svg/sprite1.svg#phone" />
                  </svg>
                </span>
                {{ contacts.phone }}
              </span>
            </li>
            <li v-if="contacts?.additional_phone" class="contact-line footer__item">
              <a
                class="contact-line__link contact-line__link--mobile"
                :href="`tel:${stripPhoneNumber(contacts.additional_phone)}`"
              >
                <span class="contact-line__icon">
                  <svg class="s-icon s-icon--xs">
                    <use xlink:href="/img/svg/sprite1.svg#phone" />
                  </svg>
                </span>
                {{ contacts.additional_phone }}
              </a>
              <span class="contact-line__link">
                <span class="contact-line__icon">
                  <svg class="s-icon s-icon--xs">
                    <use xlink:href="/img/svg/sprite1.svg#phone" />
                  </svg>
                </span>
                {{ contacts.additional_phone }}
              </span>
            </li>
            <li v-if="contacts?.whatsapp" class="contact-line footer__item">
              <a
                class="contact-line__link"
                :href="`https://wa.me/${stripPhoneNumber(contacts.whatsapp)}`"
                target="_blank"
                rel="noopener"
              >
                <span class="contact-line__icon">
                  <svg class="s-icon">
                    <use xlink:href="/img/svg/sprite1.svg#whatsapp" />
                  </svg>
                </span>
                {{ contacts.whatsapp }}
              </a>
            </li>
            <li v-if="contacts?.telegram" class="contact-line footer__item">
              <a
                class="contact-line__link"
                :href="`http://t.me/${contacts.telegram}`"
                target="_blank"
                rel="noopener"
              >
                <span class="contact-line__icon">
                  <svg class="s-icon">
                    <use xlink:href="/img/svg/sprite1.svg#telegram" />
                  </svg>
                </span>
                {{ contacts.telegram }}
              </a>
            </li>
          </ul>
        </div>
        <div class="footer__block footer__block--second">
          <div class="footer__title">
            Время работы
          </div>
          <div class="footer__item">
            ПН-СБ с 9:00 до 17:00, ВС ‒ выходной
          </div>
          <button class="footer__btn btn btn--small" @click="showFeedbackModal = true">
            обратная связь
          </button>
        </div>
      </div>
    </div>
    <div class="footer__bottom">
      <div class="container">
        <div class="footer__box">
          <p>
            Информация на сайте не является публичной офертой. Указанные цены
            действуют только при оформлении заказа через сайт lk.dsf.kz
          </p>
          <p>
            Продолжая использовать наш сайт, вы соглашаетесь
            <router-link to="/policy">
              Политикой конфиденциальности.
            </router-link>
          </p>
        </div>
      </div>
    </div>
    <feedback-modal v-model="showFeedbackModal" />
  </footer>
</template>

<script>
import FeedbackModal from './FeedbackModal.vue'
import parsePhoneNumber from 'libphonenumber-js'
import {onMounted, computed, ref} from 'vue'
import {useStore} from 'vuex'
import {FETCH_CONTACTS} from '@/store/modules/contacts/actionTypes'

export default {
  components: {FeedbackModal},
  setup() {
    const store = useStore()
    onMounted(() => store.dispatch(FETCH_CONTACTS))
    const contacts = computed(() => store.getters.getContacts)
    const showFeedbackModal = ref(false)
    const stripPhoneNumber = (phone) => {
      const phoneNumber = parsePhoneNumber(phone, 'RU')
      if (phoneNumber && phoneNumber.isValid()) {
        phone = phoneNumber.number
      }
      return phone
    }
    return {
      stripPhoneNumber,
      showFeedbackModal,
      contacts
    }
  }
};
</script>
