import Inputmask from 'inputmask'

export default {
  install(app) {
    app.directive('input-mask', {
      mounted(el, binding) {
        Inputmask(binding.value).mask(el)
      }
    })
  }
}