import BaseApi from './BaseApi'

export default class Auth extends BaseApi {
  checkRegisterToken(token) {
    return this.http.get(`/auth/register/${token}`)
  }

  register(token, form) {
    return this.http.put(`/auth/register/${token}`, form)
  }

  login(user) {
    return this.http.post('/auth/login', user)
  }

  requestPasswordRecovery(email) {
    return this.http.post('/auth/password-recovery', { email })
  }

  changePassword(token, form) {
    return this.http.put(`/auth/password-recovery/${token}`, form)
  }

  checkRecoveryToken(token) {
    return this.http.get(`/auth/password-recovery/${token}`)
  }
}
