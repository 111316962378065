<template>
  <router-view />
  <vue-progress-bar />
</template>

<script>
export default {

}
</script>

<style lang="scss">
html {
  overflow: overlay;
}
* {
  scrollbar-width: thin;
  scrollbar-color: #999b9c white;
}
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background-color: #999b9c;
  border-radius: 20px;
}

#app {
  display: flex;
  width: 100%;
  flex-direction: column;
  min-height: 100vh;
}
</style>
<style lang="scss" src="@/assets/styles/main.scss"></style>
