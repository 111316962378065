import { createAuth } from '@websanova/vue-auth'
import driverAuthBearer from './bearer'
import driverHttpAxios from '@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js'
import driverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js'

export default (router, http) => {
  const auth = createAuth({
    plugins: {
      http,
      router
    },
    drivers: {
      http: driverHttpAxios,
      auth: driverAuthBearer,
      router: driverRouterVueRouter
    },
    options: {
      notFoundRedirect: { path: '/error' },
      forbiddenRedirect: { path: '/error' },
      logoutData: {
        url: process.env.VUE_APP_API_URL + '/auth/logout'
      },
      loginData: {
        url: process.env.VUE_APP_API_URL + '/auth/login'
      },
      fetchData: {
        url: process.env.VUE_APP_API_URL + '/profile'
      },
      refreshData: {
        enabled: false
      },
      parseUserData: (response) => response || {}
    }
  })
  http.interceptors.request.use(config => {
    const token = auth.token()
    if (token) {
      config.headers.Authorization = process.env.VUE_APP_TOKEN_NAME + ` ${token}`
    }
    return config
  }, error => {
    return Promise.reject(error)
  })
  return auth
}