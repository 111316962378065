<template>
  <transition name="fade">
    <div v-if="isOpen" class="page-message">
      <div class="container">
        <div class="page-message__inner">
          <div class="page-message__text">
            Этот сайт использует файл cookie. Продолжая, вы даёте согласие на их использование.
            <a @click="showModal = true">
              Узнать больше
            </a>.
          </div>

          <button class="page-message__btn btn btn--primary" @click="closeAlert">
            Принять
          </button>
        </div>
      </div>
      <the-cookie-modal v-model="showModal" @accept="closeAlert" />
    </div>
  </transition>
</template>

<script>
import TheCookieModal from "./TheCookieModal"
import { ref } from 'vue'

export default {
  components: {TheCookieModal},
  setup() {
    const showModal = ref(false)
    const isOpen = ref(!localStorage.getItem('cookie-use-accepted'))
    const closeAlert = () => {
      isOpen.value = false
      localStorage.setItem('cookie-use-accepted', true)
    }
    return {
      isOpen,
      closeAlert,
      showModal
    }
  }
}
</script>
<style lang="scss" scoped>
.page-message {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
