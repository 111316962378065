import { SET_PRODUCTS_LOADING, SET_PRODUCTS, APPEND_PRODUCTS, SET_FOUND_PRODUCTS } from "./mutationTypes"
import { FETCH_PRODUCTS, SEARCH_PRODUCTS } from "./actionTypes"

const state = {
  foundProducts: [],
  products: [],
  isLoading: true
}

const getters = {
  getFoundProducts: state => state.foundProducts,
  getProducts: state => state.products,
  isProductsLoading: state => state.isLoading
}

const actions = {
  [SEARCH_PRODUCTS]({ commit }, params) {
    return new Promise((resolve, reject) => {
      this.$api.products.searchProducts(params).then((response) => {
        const { data } = response
        commit(SET_FOUND_PRODUCTS, data)
        resolve(response)
      }).catch((error) => {
        if (!this.$api.products.http.isCancel(error)) {
          reject(error)
        }
      })
    })
  },
  [FETCH_PRODUCTS]({ commit }, { append, params }) {
    return new Promise((resolve, reject) => {
      if (!append) {
        commit(SET_PRODUCTS_LOADING, true)
      }
      this.$api.products.fetchProducts(params, !append).then((response) => {
        const { data } = response
        commit(append ? APPEND_PRODUCTS : SET_PRODUCTS, data)
        resolve(response)
      }).catch((error) => {
        if (!this.$api.products.http.isCancel(error)) {
          reject(error)
        }
      }).finally(() => {
        if (!append) {
          commit(SET_PRODUCTS_LOADING, false)
        }
      })
    })
  }
}

const mutations = {
  [SET_FOUND_PRODUCTS]: (state, foundProducts) => {
    state.foundProducts = foundProducts
  },
  [SET_PRODUCTS]: (state, products) => {
    state.products = products
  },
  [APPEND_PRODUCTS]: (state, products) => {
    state.products.push(...products)
  },
  [SET_PRODUCTS_LOADING]: (state, isLoading) => {
    state.isLoading = isLoading
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}