import BaseApi from './BaseApi'

export default class Cart extends BaseApi {
  fetchCart(params) {
    return this.http.get(`/cart`, { params })
  }

  addItem(item, params) {
    return this.http.post('/cart', item, { params })
  }

  updateItem(id, item, params) {
    return this.http.put(`/cart/${id}`, item, { params })
  }

  removeItem(id, params) {
    return this.http.delete(`/cart/${id}`, { params })
  }

  clear(params) {
    return this.http.delete('/cart', { params })
  }

  checkout(order, params) {
    return this.http.post('/cart/checkout', order, { params })
  }

  importFile({ file, clear }) {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('clear', clear)
    return this.http.post('/cart/import', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
}
