<template>
  <vue-final-modal v-model="showModal" @closed="closeModal">
    <form class="modal-form__box form" @submit.prevent>
      <button class="modal-form__close js-close" type="button" @click="closeModal">
        <svg class="s-icon s-icon--largest">
          <use xlink:href="/img/svg/sprite1.svg#cancel" />
        </svg>
        <span class="visually-hidden">Закрыть модальное окно</span>
      </button>
      <div class="modal-form__title">
        Сменить пароль
      </div>
      <label class="field field--icon modal-form__field" :class="{ 'v-error': form.errors.password }">
        <span class="field__suptitle">Старый пароль</span>
        <input
          v-model="form.body.password"
          type="password"
          class="field__input"
          required
          :disabled="isSubmitting"
        >
        <span v-if="form.errors.password" class="field__icon" :data-r-prompt="form.errors.password">
          <svg class="s-icon">
            <use xlink:href="/img/svg/sprite1.svg#warning" />
          </svg>
        </span>
      </label>
      <label class="field field--icon modal-form__field" :class="{ 'v-error': form.errors.new_password }">
        <span class="field__suptitle">Новый пароль</span>
        <input
          v-model="form.body.new_password"
          :disabled="isSubmitting"
          type="password"
          class="field__input"
          required
        >
        <span v-if="form.errors.new_password" class="field__icon" :data-r-prompt="form.errors.new_password">
          <svg class="s-icon">
            <use xlink:href="/img/svg/sprite1.svg#warning" />
          </svg>
        </span>
      </label>
      <label class="field field--icon modal-form__field" :class="{ 'v-error': form.errors.confirmation_password }">
        <span class="field__suptitle">Новый пароль еще раз</span>
        <input
          v-model="form.body.confirmation_password"
          :disabled="isSubmitting"
          type="password"
          class="field__input"
          required
        >
        <span v-if="form.errors.confirmation_password" class="field__icon" :data-r-prompt="form.errors.confirmation_password">
          <svg class="s-icon">
            <use xlink:href="/img/svg/sprite1.svg#warning" />
          </svg>
        </span>
      </label>
      <button :disabled="isSubmitting" class="btn btn--primary btn--large" @click.prevent="changePassword">
        Сохранить
      </button>
    </form>
  </vue-final-modal>
</template>

<script>
import { catchFormError } from '@/common/catchFormError'
import { computed, reactive, ref } from 'vue'
import { VueFinalModal } from 'vue-final-modal'
import { useApi } from '@/api'
import { useToast } from 'vue-toastification'

export default {
  components: {
    VueFinalModal
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const showModal = computed({
      get() {
        return props.modelValue
      },
      set(value) {
        emit('update:modelValue', value)
      }
    })
    const form = reactive({
      body: {
        password: '',
        new_password: '',
        confirmation_password: ''
      },
      errors: {}
    })
    const isSubmitting = ref(false)
    const api = useApi()
    const toast = useToast()
    const changePassword = () => {
      isSubmitting.value = true
      api.profile.changePassword(form.body).then(() => {
        closeModal()
        toast.success('Изменения сохранены')
      }).catch(catchFormError(form, toast)).finally(() => {
        isSubmitting.value = false
      })
    }
    const closeModal = () => {
      form.body = {
        password: '',
        new_password: '',
        confirmation_password: ''
      }
      form.errors = {}
      showModal.value = false
    }
    return {
      isSubmitting,
      form,
      closeModal,
      changePassword,
      showModal
    }
  }
}
</script>


