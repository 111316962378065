<template>
  <header class="header">
    <div class="container">
      <nav class="header__nav">
        <div class="header__menu" :class="{ 'active': isMenuActive }">
          <router-link class="header__logo" to="/" @click="closeMenu">
            <img class="header__image" src="/img/logo.svg" alt="Логотип">
          </router-link>
          <ul class="header__list">
            <li class="header__item">
              <router-link class="header__link" to="/" @click="closeMenu">
                Каталог
              </router-link>
            </li>
            <li class="header__item">
              <router-link class="header__link" to="/orders" @click="closeMenu">
                История заказов
              </router-link>
            </li>
            <li class="header__item header__item--mobile header__item--divider" />
            <li class="header__item header__item--mobile">
              <a class="header__link" @click="logout">
                Выйти
              </a>
            </li>
          </ul>
          <button class="header__menu-close hamburger hamburger--spring" :class="{ 'is-active': isMenuActive }" @click="toggleMenu">
            <span class="hamburger-box">
              <span class="hamburger-inner" />
            </span>
            <span class="visually-hidden">Закрыть меню</span>
          </button>
        </div>
        <h1>{{ $route.meta.title }}</h1>
        <button v-if="$route.meta.search" class="header__mobile-btn header__mobile-btn--search" @click="showSearchInput">
          <svg class="s-icon">
            <use xlink:href="/img/svg/sprite1.svg#magnifier" />
          </svg>
          <span class="visually-hidden">Вызвать поле поиска</span>
        </button>

        <label v-if="$route.meta.showImport" class="header__mobile-btn k-file">
          <input
            class="k-file__input"
            type="file"
          >
          <svg class="s-icon">
            <use xlink:href="/img/svg/sprite1.svg#import" />
          </svg>
          <span class="visually-hidden">Импортировать файл с товарами</span>
        </label>

        <router-link class="header__navblock" to="/cart">
          <div class="header__icon">
            <svg class="s-icon">
              <use xlink:href="/img/svg/sprite1.svg#cart" />
            </svg>
          </div>
          <div v-if="cart" class="header__info">
            <div v-if="cartTotalPrice" class="header__top">
              {{ cartTotalPrice }}
            </div>
            <div v-if="cartItemsCount" class="header__text">
              <span>{{ cartItemsCount }}</span> тов.
            </div>
          </div>
          <div v-else class="header__info">
            <div class="header__top">
              0,00 ₸
            </div>
            <div class="header__text">
              <span>0</span> тов.
            </div>
          </div>
        </router-link>
        <div class="header__navblock header__navblock--profile">
          <div class="header__icon">
            <svg class="s-icon">
              <use xlink:href="/img/svg/sprite1.svg#user" />
            </svg>
          </div>
          <template v-if="name">
            <div class="header__info">
              <span class="header__top">{{ name.short }}</span>
              <div class="header__text">
                {{ user.company }}
              </div>
            </div>
            <div class="header__dropdown">
              <div class="header__top header__top--dark">
                {{ name.full }}
              </div>
              <p class="header__descr">
                {{ user.company }}
              </p>
              <p>
                <a class="header__btn btn btn--secondary btn--small" @click="showPasswordModal = true">Сменить пароль</a>
                <router-link class="header__btn header__btn--exit btn btn--secondary btn--small" to="/reminder" @click="closeMenu">
                Настройка уведомлений
                </router-link>               
              </p>
              <hr/>
              <p>
                <a class="header__btn header__btn--exit btn btn--secondary btn--small" @click="logout">Выйти</a>
              </p>              
            </div>
          </template>
        </div>
        <div v-if="$route.meta.search" class="search-block search-block--dark header__search" :class="{ 'active': isSearchActive }">
          <form class="search-block__form" @submit.prevent>
            <label class="search-block__label">
              <span class="visually-hidden">{{ $route.meta.search }}</span>
              <input
                ref="searchInput"
                v-model="query"
                class="search-block__input"
                type="search"
                :placeholder="$route.meta.search"
                @blur="isSearchActive = false"
              >  
            </label>
            <button
              class="search-block__icon"
              type="button"
              @mousedown.prevent
              @click.prevent="query = null"
            >
              <span class="visually-hidden">Искать</span>
              <svg class="s-icon s-icon--large">
                <use xlink:href="/img/svg/sprite1.svg#cancel" />
              </svg>
            </button>
          </form>
        </div>
      </nav>
    </div>
    <password-modal v-model="showPasswordModal" />
  </header>
</template>

<script>
import PasswordModal from '@/components/PasswordModal.vue'
import useCartState from '@/composables/useCartState'
import debounce from 'lodash/debounce'
import { useAuth } from '@websanova/vue-auth/src/v3.js'
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { FETCH_CART } from '@/store/modules/cart/actionTypes'
import { useRoute, useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'

export default {
  components: {
    PasswordModal
  },
  setup () {
    const auth = useAuth()
    const user = computed(() => auth.user())
    const name = computed(() => {
      if (!user.value) return null
      const { name, surname, email } = user.value
      if (name && surname) {
        const firstChar = name.charAt(0)
        return {
          short: `${firstChar}. ${surname}`,
          full: `${name} ${surname}`
        }
      } else if (!name) {
        return {
          short: surname,
          full: surname
        }
      } else if (!surname) {
        return {
          short: name,
          full: name
        }
      }
      return {
        short: email,
        full: email
      }
    })
    const store = useStore()
    const route = useRoute()
    const toast = useToast()
    onMounted(() => {
      if (route.path !== '/cart') {
        store.dispatch(FETCH_CART, { expand: 'suborders' }).catch(() => {
          toast.error('Не удалось загрузить корзину')
        })
      }
    })
    const logout = () => {
      auth.logout({ makeRequest: false, redirect: '/login' })
    }
    const { cart, cartTotalPrice, isCartLoading, cartItemsCount } = useCartState()
    const isMenuActive = ref(false)
    const toggleMenu = () => isMenuActive.value = !isMenuActive.value
    const closeMenu = () => isMenuActive.value = false
    const showPasswordModal = ref(false)    

    const router = useRouter()
    const isSearchActive = ref(false)
    const debouncedSearch = debounce((query) => {
      router.push({ path: route.path, query: { ...route.query, query } })
    }, process.env.VUE_APP_DEBOUNCE_TIME)
    const query = computed(({
      get() {
        return route.meta.search ? route.query.query : null
      },
      set(newQuery) {
        debouncedSearch(newQuery)
      }
    }))
    const searchInput = ref(null)
    const showSearchInput = () => {
      isSearchActive.value = true
      if (searchInput.value) {
        searchInput.value.focus()
      }
    }
    return {
      searchInput,
      showSearchInput,
      query,
      isSearchActive,
      showPasswordModal,
      isMenuActive,
      closeMenu,
      toggleMenu,
      logout,
      cart,
      cartItemsCount,
      isCartLoading,
      cartTotalPrice,
      auth,
      name,
      user
    }
  }
}
</script>

<style lang="scss">
#header-search-btn {
  display: inline-flex;
  vertical-align: middle;
}
</style>