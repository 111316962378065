import { SET_ORDERS_LOADING, SET_ORDERS, APPEND_ORDERS } from "./mutationTypes"
import { FETCH_ORDERS } from "./actionTypes"

const state = { 
  orders: [],
  isLoading: true
}

const getters = {
  getOrders: state => state.orders,
  isOrdersLoading: state => state.isLoading
}

const actions = {
  [FETCH_ORDERS]({ commit }, { append, params }) {
    return new Promise((resolve, reject) => {
      if (!append) {
        commit(SET_ORDERS_LOADING, true)
      }
      this.$api.orders.fetchOrders(params, !append).then((response) => {
        const { data } = response
        commit(append ? APPEND_ORDERS : SET_ORDERS, data)
        resolve(response)
      }).catch((error) => {
        if (!this.$api.orders.http.isCancel(error)) {
          reject(error)
        }
      }).finally(() => {
        if (!append) {
          commit(SET_ORDERS_LOADING, false)
        }
      })
    })
  }
}

const mutations = {
  [SET_ORDERS]: (state, orders) => {
    state.orders = orders
  },
  [APPEND_ORDERS]: (state, orders) => {
    state.orders.push(...orders)
  },
  [SET_ORDERS_LOADING]: (state, isLoading) => {
    state.isLoading = isLoading
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}