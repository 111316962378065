import BaseApi from './BaseApi'

export default class Customers extends BaseApi {
  search(params) {
    return this.http.get('/customers', { params })
  }

  fetch(id) {
    return this.http.get(`/customers/${id}`)
  }
}
