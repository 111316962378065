import BaseApi from './BaseApi'

export default class Profile extends BaseApi {
  fetchProfile() {
    return this.http.get('/profile')
  }

  changePassword(form) {
    return this.http.put('/profile/change-password', form)
  }

  update(form) {
    return this.http.put('/profile/update', form)
  }

  updateRemind(form) {
    return this.http.put('/profile/update-remind', form)
  }

  remindAddresses(params) {
    return this.http.get('/profile/remind-addresses', { params })
  }
}
