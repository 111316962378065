import BaseApi from './BaseApi'

export default class Orders extends BaseApi {
  fetchOrder(id, params) {
    return this.http.get(`/orders/${id}`, { params })
  }

  fetchOrders(params, cancelable = true) {
    const cancelToken = this.handleCancellation(this.fetchOrders.name, cancelable).token
    return this.http.get('/orders', { params, cancelToken })
  }

  repeatOrder(id, params) {
    return this.http.post(`/orders/repeat/${id}`, params)
  }

  cancelOrder(id) {
    return this.http.delete(`/orders/${id}`)
  }
}
