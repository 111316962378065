const prefixPluginName = (name) => `$${name}`

export const injectToApp = (app, injectKey, plugin) => {
  app.config.globalProperties[prefixPluginName(injectKey)] = plugin
}

export const injectToStore = (store, injectKey, plugin) => {
  Object.defineProperty(store, prefixPluginName(injectKey), {
    get: () => plugin
  })
}