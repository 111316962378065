import LayoutMain from '@/layouts/LayoutMain'
import LayoutAuth from '@/layouts/LayoutAuth'
import LayoutEmpty from "../layouts/LayoutEmpty"

import { createRouter, createWebHistory } from 'vue-router'
import isEqual from 'lodash/isEqual'

export default createRouter({
  scrollBehavior(to, from) {
    if (!isEqual(to, from)) {
      const behavior = to.path === from.path ? 'smooth' : 'auto'
      return {
        top: 0,
        behavior
      }
    }
  },
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes: [
    {
      path: '/',
      component: LayoutMain,
      children: [{
        path: '/',
        component: () => import('@/views/Catalog'),
        meta: {
          auth: true,
          search: 'Наименование или штрих-код',
          title: 'Каталог',
          showImport: true
        }
      }, {
        path: '/cart',
        component: () => import('@/views/Cart'),
        meta: {
          auth: true,
          title: 'Корзина',
          showImport: true
        }
      }, {
        path: '/checkout-success',
        component: () => import('@/views/CheckoutSuccess'),
        meta: {
          auth: true,
          title: 'Корзина'
        }
      }, {
        path: '/orders',
        component: () => import('@/views/OrderHistory'),
        meta: {
          auth: true,
          search: '№ заказа/договора или сумме заказа',
          title: 'История заказов'
        }
      }, {
        path: '/orders/:id',
        component: () => import('@/views/Order'),
        meta: {
          auth: true,
          title: 'История заказов'
        },
        props: true
      }, {
        path: '/reminder',
        component: () => import('@/views/Reminder'),
        meta: {
          auth: true,
          title: 'Настройка напоминаний'
        },
        props: true
      }]
    }, {
      path: '/',
      component: LayoutAuth,
      children: [{
        path: '/login',
        component: () => import('@/views/Login'),
        meta: {
          auth: false
        }
      }, {
        path: '/password-recovery/:token?',
        component: () => import('@/views/PasswordRecovery'),
        meta: {
          auth: false
        },
        props: true
      }, {
        path: '/register/:token',
        component: () => import('@/views/Register'),
        meta: {
          auth: false
        },
        props: true
      }]
    }, {
      path: '/',
      component: LayoutEmpty,
      children: [
        {
          path: '/policy',
          component: () => import('@/views/Policy'),
          meta: {
            title: 'Политика конфиденциальности',
          }
        },
      ]
    },

    { path: "/:catchAll(.*)", component: () => import('@/views/ErrorPage') },
  ]
})