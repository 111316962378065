import Auth from './Auth'
import Products from './Products'
import Profile from './Profile'
import Cart from './Cart'
import Orders from './Orders'
import Suborders from './Suborders'
import Contacts from './Contacts'
import Feedback from './Feedback'
import Policy from './Policy'
import Customers from './Customers'
import Addresses from './Addresses'

import { ApiService, useApi, injectKey } from './ApiService'

export const createApi = (http) => {
  return new ApiService(http, {
    Auth, Profile, Products, Cart, Orders, Suborders, Contacts, Feedback, Policy, Customers, Addresses })
}

export { useApi, injectKey }
