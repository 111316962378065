<template>
  <header class="header header--unauth">
    <div class="container">
      <nav class="header__inner">
        <router-link class="header__logo" to="/">
          <img class="header__image" src="/img/logo.svg" alt="Логотип">
        </router-link>
      </nav>
    </div>
  </header>
  <the-main />
</template>

<script>
import TheMain from '@/components/TheMain'

export default {
  components: {
    TheMain
  }
}
</script>
