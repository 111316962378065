<template>
  <vue-final-modal v-model="showModal" class="modal-import__window" @closed="showModal = false">
    <div class="modal-import__inner">
      <button class="modal-import__close" @click="showModal = false">
        <span class="visually-hidden">Закрыть модальное окно</span>
        <svg class="s-icon s-icon--largest">
          <use xlink:href="img/svg/sprite1.svg#cancel" />
        </svg>
      </button>
      <div class="modal-import__title">
        Cookie
      </div>

      <div class="modal-import__text">
        <div class="cookie-content" v-html="content" />
      </div>

      <div class="modal-import__footer">
        <button class="modal-import__btn btn btn--primary btn--small" @click="acceptCookie">
          Принять
        </button>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import {computed, ref} from 'vue'
import { VueFinalModal } from 'vue-final-modal'
import { useApi } from '@/api'

export default {
  components: {
    VueFinalModal
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'accept'],
  setup(props, { emit }) {
    const api = useApi();
    const content = ref(null)
    api.policy.fetchCookiePolicy().then(({ data }) => {
      content.value = data?.content
    })
    const showModal = computed({
      get() {
        return props.modelValue
      },
      set(value) {
        emit('update:modelValue', value)
      }
    })

    const acceptCookie = () => {
      emit('accept')
      showModal.value = false
    }

    return {
      content,
      showModal,
      acceptCookie
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-form__box {
  font-size: 16px;
}
</style>
