import { Pace } from './Pace'

export default (http) => {
  const pace = new Pace()
  http.interceptors.request.use(config => {
    if (config.progress !== false) {
      pace.start()
    }
    return config
  }, error => {
    if (error.config.progress !== false) {
      pace.finish()
    }
    return Promise.reject(error)
  })
  http.interceptors.response.use((response) => {
    if (response.config.progress !== false) {
      pace.finish()
    }
    return response
  }, error => {
    if (error.config.progress !== false) {
      pace.finish()
    }
    return Promise.reject(error)
  })
  return pace
}