import { SET_ADDRESSES_LOADING, SET_ADDRESSES, APPEND_ADDRESSES } from "./mutationTypes"
import { REMIND_ADDRESSES } from "./actionTypes"

const state = {
  foundAddresses: [],
  addresses: [],
  isLoading: true
}

const getters = {
  getAddresses: state => state.addresses,
  isAddressesLoading: state => state.isLoading
}

const actions = {
  [REMIND_ADDRESSES]({ commit }, { append, params }) {
    return new Promise((resolve, reject) => {
      if (!append) {
        commit(SET_ADDRESSES_LOADING, true)
      }
      this.$api.profile.remindAddresses(params, !append).then((response) => {
        const { data } = response
        commit(append ? APPEND_ADDRESSES : SET_ADDRESSES, data)
        resolve(response)
      }).catch((error) => {
        if (!this.$api.profile.http.isCancel(error)) {
          reject(error)
        }
      }).finally(() => {
        if (!append) {
          commit(SET_ADDRESSES_LOADING, false)
        }
      })
    })
  }
}

const mutations = {
  [SET_ADDRESSES]: (state, addresses) => {
    state.addresses = addresses
  },
  [APPEND_ADDRESSES]: (state, addresses) => {
    state.addresses.push(...addresses)
  },
  [SET_ADDRESSES_LOADING]: (state, isLoading) => {
    state.isLoading = isLoading
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}