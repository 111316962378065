import { ADD_CART_ITEM, CHECKOUT, FETCH_CART, IMPORT_FILE, REMOVE_CART_ITEM, UPDATE_CART_ITEM } from "./actionTypes"
import { SET_CART, SET_CART_LOADING, SET_CART_SUBMITTING, SET_NEW_ORDER, SET_NOT_FOUND_BARCODES, SET_PRODUCT_CART_FORMAT, SET_PRODUCT_CART_QUANTITY } from "./mutationTypes"
import { formatCurrency } from "@/common/formatNumbers"

const state = { 
  cart: null,
  products: {},
  isLoading: true,
  isSubmitting: false,
  newOrder: null,
  notFoundBarcodes: null
}

const getters = {
  getCart: state => state.cart,
  getCartProducts: state => state.products,
  getCartItemsCount: state => state.cart?.items_count,
  getCartTotalPrice: state => state.cart?.total ? formatCurrency(state.cart.total) : null,
  getCartVatAmount: state => state.cart?.vat_amount ? formatCurrency(state.cart.vat_amount) : null,
  isCartLoading: state => state.isLoading,
  isCartSubmitting: state => state.isSubmitting,
  getNewOrder: state => state.newOrder,
  getNotFoundBarcodes: state => state.notFoundBarcodes
}

const actions = {
  [IMPORT_FILE]({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit(SET_CART_LOADING, true)
      this.$api.cart.importFile(params).then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        if (error?.response?.status === 422) {
          const { data } = error.response
          commit(SET_NOT_FOUND_BARCODES, data)
        }
        reject(error)
      }).finally(() => {
        commit(SET_CART_LOADING, false)
      })
    })
  },
  [FETCH_CART] ({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit(SET_CART_LOADING, true)
      this.$api.cart.fetchCart(params).then(({ data }) => {
        commit(SET_CART, data)
        resolve(data)
      }).catch((error) => {
        reject(error)
      }).finally(() => {
        commit(SET_CART_LOADING, false)
      })
    })
  },
  [ADD_CART_ITEM]({ commit }, { item, params }) {
    return new Promise((resolve, reject) => {
      commit(SET_CART_SUBMITTING, true)
      this.$api.cart.addItem(item, params).then(({ data }) => {
        commit(SET_CART, data)
        resolve(data)
      }).catch((error) => {
        reject(error)
      }).finally(() => {
        commit(SET_CART_SUBMITTING, false)
      })
    })
  },
  [UPDATE_CART_ITEM]({ commit, state }, { id, params }) {
    const item = state.products[id]
    return new Promise((resolve, reject) => {
      commit(SET_CART_SUBMITTING, true)
      this.$api.cart.updateItem(id, item, params).then(({ data }) => {
        commit(SET_CART, data)
        resolve(data)
      }).catch((error) => {
        reject(error)
      }).finally(() => {
        commit(SET_CART_SUBMITTING, false)
      })
    })
  },
  [REMOVE_CART_ITEM]({ commit }, { id, params }) {
    return new Promise((resolve, reject) => {
      commit(SET_CART_SUBMITTING, true)
      this.$api.cart.removeItem(id, params).then(({ data }) => {
        commit(SET_CART, data)
        resolve(data)
      }).catch((error) => {
        reject(error)
      }).finally(() => {
        commit(SET_CART_SUBMITTING, false)
      })
    })
  },
  [CHECKOUT]({ commit }, { form, params }) {
    return new Promise((resolve, reject) => {
      commit(SET_CART_SUBMITTING, true)
      this.$api.cart.checkout(form, params).then(({ data }) => {
        commit(SET_NEW_ORDER, data)
        resolve(data)
      }).catch((error) => {
        reject(error)
      }).finally(() => {
        commit(SET_CART_SUBMITTING, false)
      })
    })
  }
}

const mutations = {
  [SET_PRODUCT_CART_FORMAT]: (state, { id, format }) => {
    state.products[id].format = format
  },
  [SET_PRODUCT_CART_QUANTITY]: (state, { id, quantity }) => {
    state.products[id].quantity = quantity
  },
  [SET_CART]: (state, cart) => {
    const suborders = cart?.suborders || []
    state.products = suborders.reduce((products, suborder) => {
      for (const [id, product] of Object.entries(suborder.items)) {
        products[id] = product
      }
      return products
    }, {})
    state.cart = cart
  },
  [SET_CART_LOADING]: (state, isLoading) => {
    state.isLoading = isLoading
  },
  [SET_CART_SUBMITTING]: (state, isSubmitting) => {
    state.isSubmitting = isSubmitting
  },
  [SET_NEW_ORDER]: (state, newOrder) => {
    state.newOrder = newOrder
  },
  [SET_NOT_FOUND_BARCODES]: (state, barcodes) => {
    state.notFoundBarcodes = barcodes
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}