<template>
  <main class="main">
    <router-view v-slot="{ Component, route }">
      <transition name="fade" mode="out-in">
        <component :is="Component" :key="route.path" />
      </transition>
    </router-view>
    <the-cookie-alert />
  </main>
</template>

<script>
import TheCookieAlert from './TheCookieAlert.vue';

export default {
  components: {
    TheCookieAlert
  }
}
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>