import { inject } from 'vue'
import { injectToApp } from '@/plugins'

export const injectKey = 'api'

export const useApi = () => {
  return inject(injectKey)
}

export class ApiService {
  constructor(http, repositories = {}) {
    Object.keys(repositories).forEach(key => {
      const name = key.toLowerCase()
      const instance = new repositories[key](http)
      Object.defineProperty(this, name, {
        get: () => instance
      })
    })
  }

  install(app) {
    app.provide(injectKey, this)
    injectToApp(app, injectKey, this)
  }
}