import { FETCH_CONTACTS } from "./actionTypes"
import { SET_CONTACTS, SET_CONTACTS_LOADING } from "./mutationTypes"

const state = { 
  contacts: null,
  isLoading: true
}

const getters = {
  getContacts: state => state.contacts,
  isContactsLoading: state => state.isLoading
}

const actions = {
  [FETCH_CONTACTS]({ commit }) {
    return new Promise((resolve, reject) => {
      commit(SET_CONTACTS_LOADING, true)
      this.$api.contacts.fetchContacts().then(({ data }) => {
        commit(SET_CONTACTS, data)
        resolve(data)
      }).catch((error) => {
        reject(error)
      }).finally(() => {
        commit(SET_CONTACTS_LOADING, false)
      })
    })
  }
}

const mutations = {
  [SET_CONTACTS]: (state, contacts) => {
    state.contacts = contacts
  },
  [SET_CONTACTS_LOADING]: (state, isLoading) => {
    state.isLoading = isLoading
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}