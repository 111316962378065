import BaseApi from './BaseApi'

export default class Addresses extends BaseApi {
  search(params) {
    return this.http.get('/addresses', { params, progress: false })
  }

  fetch(id) {
    return this.http.get(`/addresses/${id}`, { progress: false })
  }
}
