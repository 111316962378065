<template>
  <vue-final-modal v-model="showModal" @closed="closeModal">
    <form class="modal-form__box form" @submit.prevent>
      <button class="modal-form__close js-close" type="button" @click="closeModal">
        <svg class="s-icon s-icon--largest">
          <use xlink:href="/img/svg/sprite1.svg#cancel" />
        </svg>
        <span class="visually-hidden">Закрыть модальное окно</span>
      </button>
      <div class="modal-form__title">
        Обратная связь
      </div>
      <label class="field field--icon modal-form__field" :class="{ 'v-error': form.errors.name }">
        <span class="field__suptitle">Имя</span>
        <input
          v-model="form.body.name"
          type="text"
          class="field__input"
          placeholder="Имя сотрудника"
          required
          :disabled="isSubmitting"
        >
        <span v-if="form.errors.name" class="field__icon" :data-r-prompt="form.errors.name">
          <svg class="s-icon">
            <use xlink:href="/img/svg/sprite1.svg#warning" />
          </svg>
        </span>
      </label>
      <div class="modal-form__row">
        <label class="field field--icon modal-form__field" :class="{ 'v-error': form.errors.email }">
          <span class="field__suptitle">E-mail</span>
          <input
            v-model="form.body.email"
            :disabled="isSubmitting"
            type="email"
            class="field__input"
            placeholder="Ваш E-mail"
            required
          >
          <span v-if="form.errors.email" class="field__icon" :data-r-prompt="form.errors.email">
            <svg class="s-icon">
              <use xlink:href="/img/svg/sprite1.svg#warning" />
            </svg>
          </span>
        </label>
        <label class="field field--icon modal-form__field" :class="{ 'v-error': form.errors.phone }">
          <span class="field__suptitle">Телефон</span>
          <input
            v-model="form.body.phone"
            v-input-mask="`+7 (999) 999-99-99`"
            :disabled="isSubmitting"
            type="tel"
            class="field__input"
            placeholder="Ваш телефон"
            required
          >
          <span v-if="form.errors.phone" class="field__icon" :data-r-prompt="form.errors.phone">
            <svg class="s-icon">
              <use xlink:href="/img/svg/sprite1.svg#warning" />
            </svg>
          </span>
        </label>
      </div>
      <label class="field" :class="{ 'v-error': form.errors.message }">
        <span class="field__suptitle">Сообщение</span>
        <textarea
          v-model="form.body.message"
          :disabled="isSubmitting"
          class="field__textarea"
          placeholder="Сообщение"
        />
        <span v-if="form.errors.message" class="field__icon" :data-r-prompt="form.errors.message">
          <svg class="s-icon">
            <use xlink:href="/img/svg/sprite1.svg#warning" />
          </svg>
        </span>
      </label>
      <button :disabled="isSubmitting" class="btn btn--primary btn--large modal-form__btn" @click.prevent="sendFeedback">
        Отправить
      </button>
    </form>
  </vue-final-modal>
</template>

<script>
import { catchFormError } from '@/common/catchFormError'
import { computed, reactive, ref } from 'vue'
import { VueFinalModal } from 'vue-final-modal'
import { useApi } from '@/api'
import { useToast } from 'vue-toastification'

export default {
  components: {
    VueFinalModal
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const showModal = computed({
      get() {
        return props.modelValue
      },
      set(value) {
        emit('update:modelValue', value)
      }
    })
    const form = reactive({
      body: {
        name: '',
        phone: '',
        message: '',
        email: ''
      },
      errors: {}
    })
    const isSubmitting = ref(false)
    const api = useApi()
    const toast = useToast()
    const sendFeedback = () => {
      isSubmitting.value = true
      api.feedback.send(form.body).then(() => {
        closeModal()
        toast.success('Сообщение отправлено')
      }).catch(catchFormError(form, toast)).finally(() => {
        isSubmitting.value = false
      })
    }
    const closeModal = () => {
      form.body = {
        name: '',
        phone: '',
        message: '',
        email: ''
      }
      form.errors = {}
      showModal.value = false
    }
    return {
      isSubmitting,
      form,
      closeModal,
      sendFeedback,
      showModal
    }
  }
}
</script>


