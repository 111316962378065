export default {
  request (request, token) {
    this.drivers.http.setHeaders.call(this, request, {
      Authorization: 'Bearer ' + token
    })
  },
  response ({ data }) {
    const { access_token } = data || {}
    return access_token ? access_token : null
  }
}
